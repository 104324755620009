
import { UserObjectDataSource } from "@/data/Object/UserObjectDataSource";
import {
  mdiDotsVertical,
  mdiFolder,
  mdiFolderOpen,
  mdiMagnify,
  mdiPen,
  mdiPlusCircleOutline,
  mdiTrashCan,
} from "@mdi/js";
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
@Component({
  components: { DataEditorDialog },
})
export default class UserRoles extends Vue {
  @Prop() dataSource!: UserObjectDataSource;
  @Ref("dataEditor") dataEditor!: DataEditorDialog;
  @Prop({ default: false }) value!: boolean;
  roleDataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
    },
    className: "roles",
  });
  icons: any = {
    pen: mdiPen,
    trash: mdiTrashCan,
    search: mdiMagnify,
    dotsVertical: mdiDotsVertical,
    circlePlus: mdiPlusCircleOutline,
    folderOpen: mdiFolderOpen,
    folder: mdiFolder,
  };

  headers: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Подпись",
      value: "normalizedName",
      sortable: true,
    },
    {
      value: "actions",
      sortable: true,
      align: "right",
    },
  ];

  model: any = {
    roleId: null,
  };

  actions: any = {
    getRoles: async () => {
      return this.roleDataSource.items;
    },
  };

  fields: any = [
    {
      editor: "enum",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите роль",
        noDataText: "Данные не найдены",
      },
      name: "roleId",
      validations: [
        {
          validator: "required",
          errorMessage: "Поле не может быть пустым",
        },
      ],
      config: {
        getItems: "getRoles",
        labelKeyName: "normalizedName",
        valueKeyName: "id",
        type: "select",
      },
    },
  ];
  $message: any;
  $confirm: any;

  async created() {
    await this.roleDataSource.get();
  }

  async add() {
    const model: any = await this.dataEditor.update(this.model);
    if (!model) return;
    try {
      await this.dataSource.addRole(model.roleId);
      this.$message("Роль успешно добавлена");
    } catch (error: any) {
      this.$message(error.response.data, "error");
    }
  }

  async remove(roleId) {
    const confirm: any = await this.$confirm(
      "Вы действительно хотите удалить роль у этого пользователя",
    );
    if (!confirm) return;
    try {
      await this.dataSource.deleteRole(roleId);
      this.$message("Роль успешно удалёна");
    } catch (error: any) {
      this.$message(error.response.data, "error");
    }
  }
}
